import { Vue, Component } from 'vue-property-decorator'

@Component
export class OverlayArtistType extends Vue {
    overlayImageLink(artistId: number) {
        const path = '/img/artist-type-tiles-background/'
        switch (artistId % 9) {
            case 0:
                return `${path}Triangle1.svg`
            case 1:
                return `${path}Triangle2.svg`
            case 2:
                return `${path}Triangle3.svg`
            case 3:
                return `${path}Circle1.svg`
            case 4:
                return `${path}Circle2.svg`
            case 5:
                return `${path}Circle3.svg`
            case 6:
                return `${path}Square1.svg`
            case 7:
                return `${path}Square2.svg`
            case 8:
                return `${path}Square3.svg`
        }
    }
}
