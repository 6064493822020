


































import { Prop, Component, Mixins } from 'vue-property-decorator'
import { OverlayArtistType } from '@/mixins/overlay-artist-type'

@Component
export default class TileSlider extends Mixins(OverlayArtistType) {
    @Prop({ type: String }) parentState: string
    @Prop({ type: Array }) loadedItems: any[]

    currentIndex = 0

    get itemsToSlide() {
        return this.parentState === 'playlist' ? 5 : 6
    }

    get innerWidthTransform() {
        return this.currentIndex * (100 / this.itemsToSlide)
    }

    moveSlides(direction: string) {
        if (direction === 'prev') {
            this.currentIndex -= this.itemsToSlide
            // if we are going backwards & the tiles left are less than what a row could hold we only move an amount == to those tiles left
            // (if 2 tiles are left but we should be able to see 4 in the row, we only move back 2 indexes/tiles)
            if (this.currentIndex !== 0 - this.itemsToSlide && this.currentIndex < 0) {
                this.currentIndex = 0
            } else if (this.currentIndex < 0) {
                // (init position) if we are already at 0 and click prev, index becomes negative so we go at the very end of the array instead
                this.currentIndex = this.loadedItems.length - this.itemsToSlide
            }
        } else {
            this.currentIndex += this.itemsToSlide
            if (this.currentIndex % (this.loadedItems.length - this.itemsToSlide) < this.itemsToSlide) {
                this.currentIndex = this.loadedItems.length - this.itemsToSlide
            } else if (this.currentIndex >= this.loadedItems.length) this.currentIndex = 0
        }
    }
}
