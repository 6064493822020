var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tiles-slider--container" }, [
    _vm.loadedItems.length > _vm.itemsToSlide
      ? _c("div", { staticClass: "tiles-slider__navigation" }, [
          _c(
            "button",
            {
              staticClass: "tiles-slider__navigation--prev",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.moveSlides("prev")
                },
              },
            },
            [
              _c("icon", {
                staticClass: "icon-arrow-left",
                attrs: { name: "arrow-left" },
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass: "tiles-slider__navigation--next",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.moveSlides("next")
                },
              },
            },
            [
              _c("icon", {
                staticClass: "icon-arrow-right",
                attrs: { name: "arrow-right" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c("div", { staticClass: "tiles-slider__wrapper" }, [
      _c(
        "div",
        {
          staticClass: "tiles-slider__inner",
          style: { transform: "translateX(-" + _vm.innerWidthTransform + "%)" },
        },
        [
          _vm.parentState === "artistTypes"
            ? _vm._l(_vm.loadedItems, function (artist) {
                return _c(
                  "router-link",
                  {
                    key: artist.id,
                    staticClass: "tilePanel tilePanel--artist",
                    attrs: {
                      to: { name: "ArtistType", params: { slug: artist.slug } },
                    },
                  },
                  [
                    _c("div", { staticClass: "tilePanel__imgContainer" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.overlayImageLink(artist.id),
                            expression: "overlayImageLink(artist.id)",
                          },
                        ],
                        staticClass: "tilePanel__overlay",
                      }),
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: artist.image_url,
                            expression: "artist.image_url",
                          },
                        ],
                        staticClass: "tilePanel__img",
                      }),
                      _c("p", [_vm._v(_vm._s(artist.name))]),
                    ]),
                  ]
                )
              })
            : _vm.parentState === "topProducers"
            ? _vm._l(_vm.loadedItems, function (producer) {
                return _c(
                  "router-link",
                  {
                    key: producer.id,
                    staticClass: "tilePanel tilePanel--producer",
                    attrs: {
                      to: { name: "Producer", params: { slug: producer.slug } },
                    },
                  },
                  [
                    _c("div", { staticClass: "tilePanel__imgContainer" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: producer.image_logo_url,
                            expression: "producer.image_logo_url",
                          },
                        ],
                        staticClass: "tilePanel__img",
                      }),
                      _c("p", [_vm._v(_vm._s(producer.display_name))]),
                    ]),
                  ]
                )
              })
            : _vm.parentState === "genres"
            ? _vm._l(_vm.loadedItems, function (genre) {
                return _c(
                  "router-link",
                  {
                    key: genre.id,
                    staticClass: "tilePanel tilePanel--producer",
                    attrs: {
                      to: { name: "Genre", params: { slug: genre.slug } },
                    },
                  },
                  [
                    _c("div", { staticClass: "tilePanel__imgContainer" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: genre.image_thumbnail_url,
                            expression: "genre.image_thumbnail_url",
                          },
                        ],
                        staticClass: "tilePanel__img",
                      }),
                      _c("p", [_vm._v(_vm._s(genre.name))]),
                    ]),
                  ]
                )
              })
            : _vm.parentState === "playlists"
            ? _vm._l(_vm.loadedItems, function (playlist) {
                return _c(
                  "router-link",
                  {
                    key: playlist.id,
                    staticClass: "tilePanel tilePanel--playlist",
                    attrs: {
                      to: { name: "Playlist", params: { id: playlist.id } },
                    },
                  },
                  [
                    _c("div", { staticClass: "tilePanel__imgContainer" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: playlist.image_thumbnail_url,
                            expression: "playlist.image_thumbnail_url",
                          },
                        ],
                        staticClass: "tilePanel__img",
                      }),
                    ]),
                  ]
                )
              })
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }